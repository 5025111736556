.formauto * {
  font-size: 16px;
  font-family: "Roboto";
}

.rjsf {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.formauto {
  display: flex;
  justify-content: center;
}

.formauto .customLabelLeft {
  font-size: 16px !important;
  width: 100%;
  text-align: left;
}

.formauto .selector {
  display: flex;
  flex-direction: row;
}

.formauto .Mui-disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}

.formauto .Mui-disabled > .MuiSelect-icon {
  visibility: hidden;
}

.formauto .MuiGrid-root > .MuiFormControl-root {
  max-width: 100% !important;
  width: 100% !important;
}

.formauto #root {
  border: none;
  display: flex;
  flex-wrap: wrap;
}

.formauto #root > * {
  width: 500px;
  display: flex;
  flex-grow: 1;
}

.formauto .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.formauto .Mui-fullWidth {
  width: 100%;
  max-width: unset !important;
}

.formauto .MuiGrid-container > .MuiGrid-root .MuiGrid-item {
  max-width: 350px;
  flex-grow: 1;
}

.formauto .Mui-textArea {
  width: 800px;
  flex-grow: 1;
}
.formauto .form-control {
  border-radius: 5px !important;
  border-width: 1px !important;
  border-color: #727272 !important;
  border-style: solid !important;
  padding: 3px !important;
  padding-left: 5px !important;
  width: auto !important;
  margin: 5px;
  margin-left: 0px;
}

.formauto legend {
  font-size: 20px;
}

.formauto .field .field-string {
  width: 50%;
}

.formauto .field .field-undefined {
  width: 50%;
}

.formauto label {
  font-size: 18px !important;
  font-weight: bold;
  color: black;
}

.formauto span {
  font-weight: normal;
}

.formauto .MuiGrid-root {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
}

.formauto legend {
  font-weight: bold;
  margin-bottom: 10px;
}

.formReadOnly input[type="number"] {
  -moz-appearance: textfield !important;
  -webkit-appearance: none;
}

.formReadOnly select {
  background-color: white;
  -moz-appearance: textfield !important;
  color: black;
}

.formReadOnly .form-control {
  border-radius: 5px;
  border-width: 0px !important;
  border-color: #727272;
  border-style: solid;
  padding: 3px;
  padding-left: 5px;
  width: auto;
}
