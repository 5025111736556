.flex {
  display: flex;
}
.col {
  display: flex;
  flex-direction: column !important;
}
.row {
  display: flex;
  flex-direction: row !important;
}
.wrap {
  display: flex;
  flex-wrap: wrap !important;
}
.grow {
  display: flex;
  flex-grow: 1 !important;
}
.justify-c {
  display: flex !important;
  justify-content: center !important;
}
.justify-s {
  display: flex !important;
  justify-content: flex-start !important;
}
.justify-e {
  display: flex !important;
  justify-content: flex-end !important;
}
.justify-b {
  display: flex !important;
  justify-content: space-evenly !important;
}
.justify-o {
  display: flex !important;
  justify-content: space-between !important;
}
.align-c {
  display: flex !important;
  align-items: center !important;
}
.align-s {
  display: flex !important;
  align-items: flex-start !important;
}
.align-e {
  display: flex !important;
  align-items: flex-end !important;
}
.align-b {
  display: flex !important;
  align-items: space-evenly !important;
}
.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}
.text-r {
  text-align: right;
}
.object-cover {
  object-fit: cover;
}
.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}
.margin-top {
  margin-top: 10px !important;
}
.fullW {
  width: 100%;
}
.midW {
  width: 50%;
}
.fullH {
  height: 100%;
}
.midH {
  height: 50%;
}

.tableRow {
  display: flex;
}
